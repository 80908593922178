import React, { useCallback, useEffect, useState } from 'react';

import CartModal from 'components/modal/CartModal';
import MenuModal from 'components/modal/MenuModal';

import food from 'assets/images/food.png';

import useFetch from 'utils/hooks/useFetch';
import { GET_ALL_MENU } from 'utils/constants/api';

function Menu() {
  const { data: menu, fetch: getAllMenu } = useFetch(GET_ALL_MENU);
  const [billCode, setBillCode] = useState('');

  const [cart, setCart] = useState([]);
  const [tab, setTab] = useState(0);
  const [menuModal, setMenuModal] = useState({
    isOpen: false,
    data: {},
  });

  const [cartModal, setCartModal] = useState({
    isOpen: false,
    data: [],
  });

  const openMenuModal = (item) => {
    document.body.classList.add('modal-open');
    setMenuModal({ isOpen: true, data: item });
  };

  const closeMenuModal = () => {
    document.body.classList.remove('modal-open');
    setMenuModal({ isOpen: false, data: {} });
  };

  const handleAddToCart = (qty, cd, desc) => {
    const newCart = cart.find((item) => item.cd === cd && item?.desc === desc)
      ? cart.map((item) =>
          item?.cd === cd && item?.desc === desc
            ? { ...item, qty: item?.qty + qty }
            : { ...item }
        )
      : [...cart, { cd, qty, desc }];
    localStorage.setItem('cart', JSON.stringify(newCart));
    closeMenuModal();
    updateLocalCart();
  };

  const updateLocalCart = useCallback(() => {
    const _cart = localStorage.getItem('cart');
    const parsedCart = JSON.parse(_cart);
    if (parsedCart?.length > 0) {
      let _menu = [];

      menu?.data?.forEach((category) => {
        category?.menu?.forEach((item) => {
          _menu.push(item);
        });
      }, []);

      setCart(parsedCart);
      setCartModal({
        isOpen: true,
        data: parsedCart?.map((item, i) => ({
          ...item,
          ..._menu.find((m) => m.cd === item.cd),
        })),
      });
    } else {
      setCartModal({ isOpen: false, data: [] });
    }
  }, [menu?.data]);

  useEffect(() => {
    updateLocalCart();
  }, [updateLocalCart]);

  useEffect(() => {
    window.addEventListener('storage', updateLocalCart);
    return () => {
      window.removeEventListener('storage', updateLocalCart);
    };
  }, [updateLocalCart]);

  useEffect(() => {
    if (billCode) {
      getAllMenu({
        data: {
          bill_cd: billCode,
        },
      });
    }
  }, [billCode, getAllMenu]);

  // useEffect(() => {
  //    const urlParams = new URLSearchParams(window.location.search);
  //    const _bill_cd = urlParams.get('code') || ''
  //    if (_bill_cd) {
  //       setBillCode(_bill_cd)
  //       localStorage.setItem("bill_cd", _bill_cd)
  //    }
  // }, [])

  const updateLocalBillCode = useCallback(() => {
    const bill_cd = localStorage.getItem('bill_cd');
    if (bill_cd) {
      setBillCode(bill_cd);
    }
  }, []);

  useEffect(() => {
    updateLocalBillCode();
  }, [updateLocalBillCode]);

  useEffect(() => {
    window.addEventListener('storage', updateLocalBillCode);
    return () => {
      window.removeEventListener('storage', updateLocalBillCode);
    };
  }, [updateLocalBillCode]);

  const totalPrice = cartModal?.data?.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.price * currentItem.qty;
  }, 0);

  const totalItems = cartModal?.data?.reduce((accumulator, currentItem) => {
    return accumulator + currentItem.qty;
  }, 0);

  return (
    <div>
      <section className="app-main-container space-y-6">
        <div className="space-y-4">
          <div className="flex gap-2 overflow-x-auto pb-3 w-full">
            {menu?.data?.map((category, i) => {
              return (
                <button
                  onClick={() => setTab(i)}
                  key={i}
                  className={`min-w-fit flex items-center space-x-1 p-1 rounded-[4px] border ${
                    tab === i
                      ? 'border-app-matte-black'
                      : 'border-app-light-grey'
                  }`}
                >
                  <img className="h-8 w-8 rounded-sm" src={food} alt="food" />
                  <p className="text-sm">{category.name}</p>
                </button>
              );
            })}
          </div>
          <div>
            <p className="font-semibold text-sm">{menu?.data?.[tab]?.name}</p>
          </div>
          <div className="grid grid-cols-1 gap-4">
            {menu?.data?.[tab]?.menu?.map((item, i) => {
              return (
                <button
                  onClick={() => openMenuModal(item)}
                  key={i}
                  className="flex text-left shadow-md"
                >
                  <img
                    className="w-24 aspect-square rounded-l-md object-cover"
                    src={item?.img}
                    alt={item.nm}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = `/images/Food_Placeholder.png`;
                    }}
                  />
                  <div className="flex-1 flex flex-col justify-between p-2 gap-8 rounded-r-md text-app-matte-black">
                    <p className="text-sm">{item.nm}</p>
                    <p className="text-right text-[10px]">
                      Rp {new Intl.NumberFormat().format(item.price)}
                    </p>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </section>
      {menuModal?.isOpen && (
        <MenuModal
          onClose={closeMenuModal}
          image_url={menuModal.data.img}
          cd={menuModal.data.cd}
          name={menuModal.data.name}
          price={menuModal.data.price}
          onAddToCart={handleAddToCart}
        />
      )}
      {cartModal?.isOpen && (
        <CartModal totalItems={totalItems} totalPrice={totalPrice} />
      )}
    </div>
  );
}

export default Menu;
