import EllipseIcon from 'assets/icons/EllipseIcon'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function CartModal({
   totalPrice,
   totalItems
}) {
   const navigate = useNavigate()

   return (
      <div className="fixed bottom-0 left-0 right-0 flex items-center justify-center z-30 p-4 bg-white">
         <div onClick={() => navigate("/cart")} className="flex justify-between bg-app-green rounded-md w-full text-white px-4 py-3">
            <div className="flex justify-between items-center gap-2">
               <div className="font-semibold">Cart</div>
               <div><EllipseIcon /></div>
               <div>{totalItems} item{totalItems > 1 ? "s" : ''}</div>
            </div>
            <div className="font-semibold">Rp {new Intl.NumberFormat().format(totalPrice)}</div>
         </div>
      </div>
   )
}

export default CartModal