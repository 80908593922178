import React, { useEffect, useState } from 'react';

import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { GET_BILL } from 'utils/constants/api';
import useFetch from 'utils/hooks/useFetch';
import logo from 'assets/images/logo.png';
import { useCallback } from 'react';
import SpinnerIcon from 'assets/icons/SpinnerIcon';

function MainContainer({ children }) {
  const {
    data: bill,
    loading: loadingBillDetail,
    error: errorBillDetail,
    fetch: getBillDetail,
  } = useFetch(GET_BILL);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [billCode, setBillCode] = useState('');

  useEffect(() => {
    if (billCode) {
      getBillDetail({
        data: {
          bill_cd: billCode,
        },
      });
    }
  }, [billCode, getBillDetail]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const _bill_cd = urlParams.get('code') || '';
    if (_bill_cd) {
      setBillCode(_bill_cd);
      localStorage.setItem('bill_cd', _bill_cd);
    }
  }, []);

  const updateLocalBillCode = useCallback(() => {
    const bill_cd = localStorage.getItem('bill_cd');
    if (bill_cd) {
      setBillCode(bill_cd);
    }
  }, []);

  useEffect(() => {
    updateLocalBillCode();
  }, [updateLocalBillCode]);

  useEffect(() => {
    window.addEventListener('storage', updateLocalBillCode);
    return () => {
      window.removeEventListener('storage', updateLocalBillCode);
    };
  }, [updateLocalBillCode]);

  useEffect(() => {
    if (
      window.location.pathname !== '/order' &&
      bill?.bill_status?.toUpperCase() === 'CLOSED'
    ) {
      window.location.assign('/order');
    }
  }, [bill?.bill_status]);

  if (!billCode) {
    return (
      <div className="max-w-md w-full mx-auto h-screen flex flex-col justify-center items-center space-y-4 text-center p-8">
        <img src={logo} alt="logo" className="h-12 w-12 object-contain" />
        <p className="text-2xl font-semibold">Not Allowed</p>
        <p>Kindly use the QR code to access the menu</p>
      </div>
    );
  }

  if (billCode && errorBillDetail) {
    return (
      <div className="max-w-md w-full mx-auto h-screen flex flex-col justify-center items-center space-y-4 text-center p-8">
        <img src={logo} alt="logo" className="h-12 w-12 object-contain" />
        <p className="text-2xl font-semibold">Something went wrong</p>
        <p>
          We're experiencing server problems and are actively addressing the
          issue. Thank you for your understanding.
        </p>
      </div>
    );
  }

  if (billCode && loadingBillDetail) {
    return (
      <div className="max-w-md w-full mx-auto h-screen flex flex-col justify-center items-center space-y-4 text-center p-8">
        <SpinnerIcon className="w-8 h-8 fill-app-matte-black animate-spin-slow" />
      </div>
    );
  }

  if (
    !bill?.bill_status ||
    bill?.bill_status?.toUpperCase() === 'PAID' ||
    bill?.bill_status?.toUpperCase() === 'EMPTY'
  ) {
    return (
      <div className="max-w-md w-full mx-auto h-screen flex flex-col justify-center items-center space-y-4 text-center p-8">
        <img src={logo} alt="logo" className="h-12 w-12 object-contain" />
        <p className="text-2xl font-semibold">Invalid Session</p>
        <p>
          Please use a valid QR Code or ask the waiter to provide you with new
          QR code to access the menu.
        </p>
      </div>
    );
  }

  return (
    <div className="pb-24">
      <Navbar table_nm={`Table ${bill?.data?.[0]?.table_cd}`} />
      {children}
      <Sidebar isOpen={isMenuOpen} onClose={() => setMenuOpen(false)} />
    </div>
  );
}

export default MainContainer;
