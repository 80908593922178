import React, { useState } from 'react'
import CloseSquaredIcon from 'assets/icons/CloseSquaredIcon'
import MinusCircledIcon from 'assets/icons/MinusCircledIcon'
import PlusCircledIcon from 'assets/icons/PlusCircledIcon'

function MenuModal({
   onClose,
   image_url,
   cd,
   name,
   price,
   onAddToCart,
}) {
   const [quantity, setQuantity] = useState(1)
   const [desc, setDesc] = useState("")
   const addItem = () => {
      setQuantity(prev => prev + 1)
   }

   const removeItem = () => {
      if (quantity > 1) {
         setQuantity(prev => prev - 1)
      }
   }

   const handleAddToCart = () => {
      onAddToCart(quantity, cd, desc)
   }

   return (
      <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center z-40">
         <div className="bg-white z-50 app-modal-width p-5 sm:p-10 flex flex-col gap-6 shadow-md">
            <div className="text-right">
               <button onClick={onClose}>
                  <CloseSquaredIcon />
               </button>
            </div>
            <div>
               <img
                  className="aspect-square w-full object-contain border border-app-matte-black"
                  src={image_url}
                  alt={name}
                  onError={({ currentTarget }) => {
                     currentTarget.onerror = null;
                     currentTarget.src = `/images/Food_Placeholder.png`;
                  }}
               />
            </div>
            <div>
               <p className="text-sm">{name}</p>
            </div>
            <div className="flex flex-col space-y-2 text-sm">
               <label className="font-medium" htmlFor="notes">Notes</label>
               <input
                  id="notes"
                  className="px-3 py-2 border rounded-lg"
                  placeholder='Enter your notes here'
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
               />
            </div>
            <div className="flex justify-between text-sm">
               <div className="flex items-center gap-4">
                  <button onClick={removeItem}>
                     <MinusCircledIcon />
                  </button>
                  <button>{quantity}</button>
                  <button onClick={addItem}>
                     <PlusCircledIcon />
                  </button>
               </div>
               <div className='font-semibold text-base'>Rp {new Intl.NumberFormat().format(price)}</div>
            </div>
            <button onClick={handleAddToCart} className='font-semibold bg-app-matte-black rounded px-4 py-3 text-white text-sm'>
               Add to cart
            </button>
         </div>
         <div className="fixed top-0 bottom-0 left-0 right-0 bg-app-matte-black opacity-60 z-40" />
      </div>
   )
}

export default MenuModal