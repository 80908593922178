import React from 'react'
import logo from "assets/images/logo.png"
import RefreshButton from 'components/button/RefreshButton'

function Navbar() {
   return (
      <div className={`sticky top-0 bg-white transition-all z-30 shadow-md`}>
         <div className='app-nav-container space-y-4'>
            <div className={`flex justify-between items-center md:text-xs lg:text-base`}>
               <a href='/'>
                  <img src={logo} alt="logo" className="h-8 w-8 object-contain" />
               </a>
               <a
                  href="/order"
                  className='text-app-matte-black text-sm font-semibold border border-app-matte-black py-1 px-5 rounded-sm'
               >
                  Order List
               </a>
               <RefreshButton />
            </div>
         </div>
      </div>

   )
}

export default Navbar