import Menu from "pages/Menu";
import Order from "pages/Order";
import Cart from 'pages/Cart';

export const routes = [
   {
      path: "/",
      element: <Menu />
   },
   {
      path: "/order",
      element: <Order />
   },
   {
      path: "/cart",
      element: <Cart />
   },
]
