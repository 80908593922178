export const SERVICE_FEE = 0.05;
export const TAX_FEE = 0.1;
export const SERVICE_FEE_MULTIPLIER = 1.05
export const TAX_FEE_MULTIPLIER = 1.1

export const formatPriceNumber = (number) => {
   return `Rp ${new Intl.NumberFormat().format(number || 0)}`
}

export const subtotal = (items) => items?.reduce((accumulator, currentItem) => {
   return accumulator + (currentItem.price * currentItem.qty);
}, 0)

export const calculateSubtotal = (items) => formatPriceNumber(subtotal(items));
export const calculateService = (items) => formatPriceNumber(subtotal(items) * SERVICE_FEE);
export const calculateTax = (items) => formatPriceNumber((subtotal(items) * SERVICE_FEE_MULTIPLIER) * TAX_FEE);
export const calculateTotal = (items) => formatPriceNumber(subtotal(items) * SERVICE_FEE_MULTIPLIER * TAX_FEE_MULTIPLIER);