import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon'
import MinusCircledIcon from 'assets/icons/MinusCircledIcon'
import PlusCircledIcon from 'assets/icons/PlusCircledIcon'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ADD_BILL_DETAIL, GET_ALL_MENU } from 'utils/constants/api'
import useFetch from 'utils/hooks/useFetch'
import food from "assets/images/food.png"

import { io } from "socket.io-client";
import ConfirmationModal from 'components/modal/ConfirmationModal'
import SuccessModal from 'components/modal/SuccessModal'
const socket = io(process.env.REACT_APP_SOCKET_URL);

function Cart() {
    const { data: menu, loading: loadingMenu, error: errorMenu, fetch: getAllMenu } = useFetch(GET_ALL_MENU)
    const { isSubmitting: submittingBillDetail, error: errorAddBillDetail, fetch: addBillDetail } = useFetch(ADD_BILL_DETAIL)

    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [billCode, setBillCode] = useState('')
    const [confirmationModal, setConfirmationModal] = useState({ isOpen: false, data: {} })
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const totalPrice = data?.reduce((accumulator, currentItem) => {
        return accumulator + (currentItem.price * currentItem.qty);
    }, 0) || 0;

    const addItem = (item) => {
        const _cart = localStorage.getItem("cart")
        const cart = JSON.parse(_cart)
        const updatedCart = cart?.map(c => c?.cd === item?.cd && c?.desc === item?.order_desc ? ({ ...c, qty: c.qty + 1 }) : c)
        localStorage.setItem("cart", JSON.stringify(updatedCart))
        updateLocalCart()
    }

    const removeItem = (item) => {
        const _cart = localStorage.getItem("cart")
        const cart = JSON.parse(_cart)
        const updatedCart = cart?.map(c => c?.cd === item?.cd && c.qty > 1 && c?.desc === item?.order_desc ? ({ ...c, qty: c.qty - 1 }) : c)
        localStorage.setItem("cart", JSON.stringify(updatedCart))
        updateLocalCart()
    }

    const deleteItem = (item) => {
        const _cart = localStorage.getItem("cart")
        const cart = JSON.parse(_cart)
        const updatedCart = cart?.filter(c => c.cd !== item.cd || c?.desc !== item?.order_desc)
        localStorage.setItem("cart", JSON.stringify(updatedCart))
        updateLocalCart()
    }

    const updateLocalCart = useCallback(() => {
        const _cart = localStorage.getItem("cart")
        const parsedCart = JSON.parse(_cart)
        const _data = parsedCart?.map((item, i) => {
            let _menu = []
            menu?.data?.forEach((category) => {
                category?.menu?.forEach(item => {
                    _menu.push(item)
                })
            }, [])
            return ({ ...item, ..._menu.find(m => m.cd === item.cd), order_desc: item?.desc })
        }) || []
        setData(_data)
    }, [menu])

    const handleOrder = async () => {
        const _cart = localStorage.getItem("cart")
        const parsedCart = JSON.parse(_cart)
        const res = await addBillDetail({
            data: {
                bill_cd: billCode,
                orders: parsedCart?.map(item => ({
                    ...item,
                    user_nm: "Nicolas",
                    created_by: "User",
                    qty: item.qty.toString(),
                    menu_cd: item.cd
                }))
            }
        })
        setConfirmationModal({ isOpen: false, data: {} })
        if (!res?.error) {
            setShowSuccessModal(true)
            socket.emit("new-order", data)
            localStorage.removeItem("cart")

            setTimeout(() => {
                setShowSuccessModal(false)
                navigate("/order")
                window.location.reload();
            }, 1500)
        }
    }

    useEffect(() => {
        updateLocalCart()
    }, [updateLocalCart])

    useEffect(() => {
        window.addEventListener("storage", updateLocalCart);
        return () => {
            window.removeEventListener("storage", updateLocalCart)
        }
    }, [updateLocalCart])

    useEffect(() => {
        if (billCode) {
            getAllMenu({
                data: {
                    bill_cd: billCode
                }
            })
        }
    }, [billCode, getAllMenu])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const _bill_cd = urlParams.get('code') || ''
        if (_bill_cd) {
            setBillCode(_bill_cd)
            localStorage.setItem("bill_cd", _bill_cd)
        }
    }, [])

    const updateLocalBillCode = useCallback(() => {
        const bill_cd = localStorage.getItem("bill_cd")
        if (bill_cd) {
            setBillCode(bill_cd)
        }
    }, [])

    useEffect(() => {
        updateLocalBillCode()
    }, [updateLocalBillCode])

    useEffect(() => {
        window.addEventListener("storage", updateLocalBillCode);
        return () => {
            window.removeEventListener("storage", updateLocalBillCode)
        }
    }, [updateLocalBillCode])

    const isLoading = loadingMenu || submittingBillDetail
    const disableOrder = isLoading || errorMenu || data?.length < 1

    return (
        <div>
            <section className='app-main-container'>
                <div className="grid grid-cols-3 -mx-4 px-4 py-1">
                    <button onClick={() => navigate('/')} className="text-left">
                        <ArrowLeftIcon />
                    </button>
                    <p className="text-center font-semibold">Cart</p>
                </div>
                <div className='grid grid-cols-1'>
                    {data?.map((item, i) => (
                        <div key={i} className='py-4 -mx-4 px-4 space-y-4'>
                            <div className='flex gap-2'>
                                <img className="w-24 h-24" src={food} alt="food" />
                                <div className='flex-1 flex flex-col justify-between'>
                                    <div className="space-y-2">
                                        <p className='text-sm'>{item.nm}</p>
                                        {item.order_desc && (
                                            <p className='text-xs'>Notes: <span className="text-app-grey">{item.order_desc}</span></p>
                                        )}
                                        <p className="text-xs">Rp {new Intl.NumberFormat().format(item.price || 0)}</p>
                                    </div>
                                    <p className="text-right font-semibold text-xs">Rp {new Intl.NumberFormat().format(item.qty * item.price || 0)}</p>
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <div className="flex items-center gap-4">
                                    <button onClick={() => removeItem(item)}>
                                        <MinusCircledIcon />
                                    </button>
                                    <div className='text-sm'>{item.qty}</div>
                                    <button onClick={() => addItem(item)}>
                                        <PlusCircledIcon />
                                    </button>
                                </div>
                                <div>
                                    <button className='text-[10px] px-4 py-1 rounded-md border border-app-matte-black font-semibold' onClick={() => deleteItem(item)}>
                                        Remove
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <div className="fixed bottom-0 left-0 right-0 flex flex-col items-center justify-center z-30 p-4 bg-white shadow-xl">
                <div className='flex justify-between w-full text-sm mb-4'>
                    <p className='font-semibold'>Total</p>
                    <p className='font-semibold'>Rp {new Intl.NumberFormat().format(totalPrice)}</p>
                </div>
                <button
                    disabled={disableOrder}
                    onClick={() => setConfirmationModal({ isOpen: true, data: { onSuccess: handleOrder } })}
                    className={`${disableOrder ? "bg-app-light-grey" : "bg-app-matte-black"} ${errorAddBillDetail ? "border border-red-500" : ""} rounded-md w-full text-white px-4 py-3 font-semibold text-sm mb-2`}
                >
                    Order
                </button>
                {errorAddBillDetail && (
                    <div className="text-right w-full text-sm text-red-500">
                        <p>{errorAddBillDetail?.response?.data?.data?.toString() || "Failed to order"}</p>
                    </div>
                )}
            </div>
            {confirmationModal?.isOpen && (
                <ConfirmationModal
                    onClose={() => setConfirmationModal({ isOpen: false, data: {} })}
                    data={confirmationModal?.data}
                />
            )}
            {showSuccessModal && (
                <SuccessModal
                    title="Order Placed Successfully!"
                    subtitle="Your delicious choices have been sent to the kitchen. Enjoy your meal!"
                />
            )}
        </div>
    )
}

export default Cart