import ArrowLeftIcon from 'assets/icons/ArrowLeftIcon';
import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom';
import { GET_BILL } from 'utils/constants/api';
import { calculateService, calculateSubtotal, calculateTax, calculateTotal } from 'utils/helpers';
import useFetch from 'utils/hooks/useFetch';

function Order() {
   const navigate = useNavigate();
   const { data: bill, loading: loadingBillDetail, fetch: getBillDetail } = useFetch(GET_BILL)

   useEffect(() => {
      const bill_cd = localStorage.getItem("bill_cd")
      getBillDetail({
         data: {
            bill_cd
         }
      })
   }, [getBillDetail])

   if (loadingBillDetail) {
      return (
         <div>
            <section className="app-main-container space-y-6">
               <div className="grid grid-cols-3 -mx-4 px-4 py-1">
                  <button onClick={() => navigate('/')} className="text-left">
                     <ArrowLeftIcon />
                  </button>
                  <p className="text-center font-medium">Order List</p>
               </div>
            </section>
         </div>
      )
   }

   if (bill?.data?.length <= 0) {
      return (
         <div>
            <section className="app-main-container space-y-6">
               <div className="grid grid-cols-3 -mx-4 px-4 py-1">
                  <button onClick={() => navigate('/')} className="text-left">
                     <ArrowLeftIcon />
                  </button>
                  <p className="text-center font-medium">Order List</p>
               </div>
               <div className="flex justify-center py-4">
                  <p className='text-lg text-center font-medium'>Empty order</p>
               </div>
            </section>
         </div>
      )
   }
   return (
      <div>
         <section className="app-main-container space-y-6">
            <div className="grid grid-cols-3 -mx-4 px-4 py-1">
               <button onClick={() => navigate('/')} className="text-left">
                  <ArrowLeftIcon />
               </button>
               <p className="text-center font-medium">Order List</p>
            </div>
            <div className="-mx-4 px-4 text-sm">
               {bill?.bill_status?.toUpperCase() === 'CLOSED' && (
                  <p className='my-1'>Bill Status: <span className="font-semibold">{bill?.bill_status?.toLowerCase().replace(bill?.bill_status?.toLowerCase()?.[0], bill?.bill_status.toUpperCase()?.[0])}</span></p>
               )}
               {bill?.data?.[0]?.table_cd && (
                  <p className='my-1'>Table Number: <span className="font-semibold">{bill?.data?.[0]?.table_cd}</span></p>
               )}
            </div>
            <table className="table-auto w-full border-b border-app-matte-black">
               <thead>
                  <tr className='text-xs sm:text-sm'>
                     <th className="text-left px-1 py-2 border-b border-app-matte-black">Qty</th>
                     <th className="text-left px-1 py-2 border-b border-app-matte-black">Item</th>
                     <th className="text-right px-1 py-2 border-b border-app-matte-black">Price</th>
                  </tr>
               </thead>
               <tbody>
                  {bill?.data?.map((order, i) => (
                     <tr className='text-xs sm:text-sm'>
                        <td className="text-left px-1 py-2">{order?.qty}</td>
                        <td className="text-left px-3 py-2">
                           <p>{order.menu_nm}</p>
                           {order.desc && (
                              <p>Notes: <span className="text-app-grey">{order.desc}</span></p>
                           )}
                        </td>
                        <td className="text-right px-1 py-2 whitespace-nowrap">Rp {new Intl.NumberFormat().format(order.total)}</td>
                     </tr>
                  ))}
               </tbody>
            </table>
            <div className="grid grid-cols-3 gap-2 text-right text-sm">
               <div className="col-span-2">Subtotal</div>
               <div>{calculateSubtotal(bill?.data)}</div>
               <div className="col-span-2">Service</div>
               <div>{calculateService(bill?.data)}</div>
               <div className="col-span-2">Tax</div>
               <div>{calculateTax(bill?.data)}</div>
               <div className="col-span-2">Total</div>
               <div className='font-bold'>{calculateTotal(bill?.data)}</div>
            </div>
         </section>
      </div>
   )
}

export default Order