export const API_BASE_URL = process.env.REACT_APP_API_URL

export const GET_ALL_MENU = {
   method: 'post',
   url: "/menu"
}

export const ADD_BILL_DETAIL = {
   method: 'post',
   url: "/bulk-add-bill-detail"
}

export const GET_BILL = {
   method: 'post',
   url: "/get-bill-detail-by-cd"
}
